import React from 'react';

import giftTop from '../../../assets/images/checkoutGift/gift_top2.png';
import giftCenter from '../../../assets/images/checkoutGift/gift1.png';
import giftLeft from '../../../assets/images/checkoutGift/gift_left2.png';
import bg from '../../../assets/images/checkoutGift/bg_orange.svg';
import star from '../../../assets/images/checkoutGift/star2.png';
import { translate } from '@/utils';
import { useStores } from '@/store';

const GiftOrange = () => {
  const { app_info, ln } = useStores().appStore;
  const images = {
    default: [
      'en_phone1.png',
      'en_phone2_new.png',
      'en_phone3.png',
      'en_phone4.png',
      'en_phone5.png',
    ],
    ru: ['ru_phone1.png', 'ru_phone2.png', 'ru_phone3.png', 'ru_phone4.png', 'ru_phone5.png'],
  };
  const currentImages = images[ln] || images.default;
  return (
    <div className="gift-orange">
      <img className="gift-orange__gift-top" src={giftTop} alt="" />
      <img className="gift-orange__gift-center" src={giftCenter} alt="" />
      <img className="gift-orange__gift-left" src={giftLeft} alt="" />
      <img className="gift-orange__bg" src={bg} alt="" />
      <div className="gift-orange__inner">
        <div className="gift-orange__title">{translate('GIFT_TITLE')}</div>
        <div className="gift-orange__note">
          {translate('GIFT_NOTE').replace(
            /{{project_name}}/g,
            app_info?.project_name || 'VELLNESS',
          )}
        </div>
        <div className="gift-orange__rating">
          {translate('GIFT_RATING')} <span>{translate('GIFT_RATING_VALUE')}</span>{' '}
          <img src={star} alt="" />
        </div>
      </div>
      <div className="gift-orange__list">
        <img className="gift-orange__gift-m" src={giftCenter} alt="" />
        {currentImages.map((img, i) => {
          return (
            <div key={img} className="gift-orange__list-item">
              <img className={`gift-orange__phone${i + 1}`} src={`/images/gift/${img}`} alt="" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GiftOrange;
