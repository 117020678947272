import surveyData1 from './short-quiz';
import surveyData2 from './long-quiz';
import surveyDataMetric from './short-quiz-metr';
import emailPhone from './email-phone-quiz';

const EMPTY_QUIZ_ENABLED = Number(process.env.REACT_APP_EMPTY_QUIZ);
const EMPTY_QUIZ = {
  pages: [
    {
      name: 'page21',
      elements: [
        {
          type: 'text',
          name: 'email',
          title: 'Your e-mail',
          isRequired: true,
          inputType: 'email',
        },
      ],
      title: 'Enter your email to see how much weight you can lose for good with Vellness. ',
      description:
        'Vellness does not share any personal information.\n' +
        'Well email you a copy of your results for convenient access.',
    },
    {
      name: 'page18',
      elements: [
        {
          type: 'html',
          name: 'question3',
        },
        {
          type: 'html',
          name: 'question13',
          html:
            '<p class="just_question">As it is not a ready-to-use program but a customized course, you will be able to alter the settings so that Vellness fits in your daily routine and does not demand a lot of time and effort. Even spending 10 minutes a day Vellnessing, you will soon notice great lifestyle improvements leading you to your 2 major goals. We started with them, remember?</p>',
        },
      ],
      title: 'Get my personal course',
    },
  ],
  showNavigationButtons: 'none',
  showQuestionNumbers: 'off',
  questionErrorLocation: 'bottom',
  showProgressBar: 'top',
};

export const QUIZ_JSONS = EMPTY_QUIZ_ENABLED
  ? [{ surveyData1: EMPTY_QUIZ }, { surveyData2: EMPTY_QUIZ }]
  : [{ surveyData1 }, { surveyData2 }];

export const METRIC_SYSTEM_QUESTIONS = { surveyDataMetric };

export const EMAIL_PHONE_QUESTION = { emailPhone };
