import React from 'react';
import { useStores } from '@/store';
import { translate } from '@/utils';
import { Helmet } from 'react-helmet';

const RefundPolicy = () => {
  const { app_info } = useStores().appStore;

  const email = 'support@verityapps.com';

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: translate('REFUND_POLICY_CONTENT')
            .replace(/{{project_name}}/g, app_info.project_name || '{{project_name}}')
            .replace(/{{company_name}}/g, 'ADZ VERITYAPPS LTD')
            .replace(/{{support_email}}/g, email)
            .replace(
              /{{company_address}}/g,
              app_info.company_address ||
                'SUBSTER UK LTD 41 Devonshire Street, Ground Floor, London, England, W1G 7AJ, reg. number: 13668795',
            ),
        }}
      />
      <Helmet>
        <link rel="canonical" href="https://lunaraapp.com/en/privacy" />
      </Helmet>
    </>
  );
};

export default RefundPolicy;
