import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { NavLink, withRouter } from 'react-router-dom';
import { translate } from '../../utils';
import breakpoints from '../../utils/breakpoints';
import colorsVars from '../../utils/colorsVars';
import MediaQuery from 'react-responsive/src/Component';
import { Link as ScrollLink } from 'react-scroll';
import { debounce } from 'lodash';

import LangSwitcher from './LangSwitcher';
import LoginModal from './LoginModal';
import SocialItem from '../HomePage/SocialItem';
import RenderSvg from '../RenderSvg';

import logoIcon from '../../assets/icons/logo_icon.png';
import logoText from '../../assets/icons/logo_name.svg';
import signIn from '../../assets/icons/sign-in_icon.svg';
import signUp from '../../assets/icons/sign-up_icon.svg';
import supportIcon from '../../assets/icons/support.svg';
import burger from '../../assets/icons/burger.svg';
import { gaMirror } from '@utils/metrics';
import { addHostToPath } from '@/utils';
import { LangDynamicLink } from '@utils/LinkLangRoutes';
import Routes from '@utils/Routes';

@inject('commonStore')
@inject('appStore')
@observer
class NotAuthHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBurgerMenu: false,
      // eslint-disable-next-line react/no-unused-state
      showLoginForm: false,
      headerLogoIsVisible: true,
    };
  }

  componentDidMount() {
    document.addEventListener('scroll', this.setHeaderLogoVisibility);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.setHeaderLogoVisibility);
  }

  renderBurger() {
    return (
      <div className="home-header__burger">
        {this.props.showBurger && (
          <span
            onClick={() => {
              this.toggleBurgerMenu();
            }}
            className="home-header__burger-icon"
          >
            <RenderSvg path={burger} color={colorsVars.colorAccent} size={28} />
          </span>
        )}
      </div>
    );
  }

  signUpHandleClick() {
    gaMirror({
      event: 'sign_up',
      category: 'authorization',
      action: 'click',
      label: 'sign_up',
    });
    this.props.history.push(LangDynamicLink('quiz'));
  }

  singInHandleClick() {
    const { appStore } = this.props;
    /* const { commonStore } = this.props; */
    gaMirror({
      event: 'sign_in',
      category: 'authorization',
      action: 'click',
      label: 'sign_in',
    });
    window.location.href = `${appStore.app_info.cabinet_link}?lang=${appStore.ln}`;
    /* commonStore.toggleLogInPopUp(); */
  }

  renderSignButtons() {
    const { app_info } = this.props.appStore;
    return (
      <>
        <button
          className="home-header__menu-btn home-header__menu-btn_sign-up "
          onClick={() => {
            this.signUpHandleClick();
          }}
        >
          <RenderSvg path={signUp} size={14} />
          <span>{translate('SIGN_UP')}</span>
        </button>
        <button
          onClick={() => {
            this.singInHandleClick();
          }}
          className="home-header__menu-btn home-header__menu-btn_sign-in"
        >
          <RenderSvg path={signIn} size={14} />
          <span>{translate('SIGN_IN')}</span>
        </button>
        {app_info?.support_site && (
          <a
            href={app_info.support_site}
            target="_blank"
            className="home-header__menu-btn home-header__menu-btn_support"
            rel="noreferrer"
          >
            <RenderSvg path={supportIcon} color={colorsVars.colorLight} size={14} />
            <span>{translate('PAYMENT_SUPPORT')}</span>
          </a>
        )}
      </>
    );
  }

  toggleBurgerMenu() {
    const { commonStore } = this.props;
    commonStore.setFullPageSliderWheelStatus(this.state.showBurgerMenu);
    this.setState({ showBurgerMenu: !this.state.showBurgerMenu });
  }

  setHeaderLogoVisibility = debounce(() => {
    if (window.innerWidth <= parseInt(breakpoints.xs) && window.scrollY > 0) {
      this.setState({ headerLogoIsVisible: false });
    } else {
      this.setState({ headerLogoIsVisible: true });
    }
  }, 50);

  render() {
    const { commonStore, appStore } = this.props;
    const isMainPage = this.props.location.pathname === `/${appStore.ln}/`;

    return (
      <>
        <div className="home-header">
          <div className="home-header__container">
            {this.renderBurger()}
            <div
              className={`home-header__logo ${
                !this.state.headerLogoIsVisible ? 'home-header__logo_hidden' : ''
              }`}
            >
              <NavLink to={Routes.home} className="full-logo">
                <img
                  className="full-logo__icon"
                  src={addHostToPath(appStore.app_info?.logo) || logoIcon}
                  alt=""
                />
                {!appStore.app_info?.logo ||
                  (appStore.app_info?.project_name.toLowerCase() === 'vellness' && (
                    <MediaQuery minWidth={breakpoints.sm}>
                      <RenderSvg
                        path={logoText}
                        widthSize={130}
                        heightSize={50}
                        className="full-logo__text"
                      />
                    </MediaQuery>
                  ))}
              </NavLink>
            </div>
            <MediaQuery minWidth={breakpoints['sm-plus']}>
              <div className="home-header__lang-switcher">
                <LangSwitcher />
              </div>
            </MediaQuery>
            <div className="home-header__menu">
              <MediaQuery minWidth={breakpoints['lg-plus']}>
                <div className="home-header__menu-list">
                  {commonStore.menuAnchorsArr?.length && isMainPage
                    ? commonStore.menuAnchorsArr.map((anchor, i) => {
                        return (
                          <div
                            key={`anchor${i}`}
                            onClick={() => {
                              commonStore.setFullPageSlideIndex(anchor.slideIndex);
                            }}
                            className="home-header__menu-item"
                          >
                            {translate(anchor.translateKey).replace(
                              /{{project_name}}/g,
                              appStore.app_info?.project_name || 'Vellness',
                            )}
                          </div>
                        );
                      })
                    : null}
                </div>
              </MediaQuery>
              {this.props.showSignButtons && (
                <div className="home-header__menu-buttons">{this.renderSignButtons()}</div>
              )}
            </div>
          </div>
          <div
            onClick={() => {
              this.toggleBurgerMenu();
            }}
            className={`home-header__overlay ${
              this.state.showBurgerMenu ? 'home-header__overlay_show' : ''
            }`}
          />
          <div
            className={`home-header__drop-menu ${
              this.state.showBurgerMenu ? 'home-header__drop-menu_open' : ''
            }`}
          >
            <div className="home-header__drop-top">
              {this.renderBurger()}
              <LangSwitcher />
            </div>
            <div className="home-header__drop-list">
              {commonStore.menuAnchorsArr?.length && isMainPage
                ? commonStore.menuAnchorsArr.map((anchor, i) => {
                    if (window.innerWidth >= parseInt(breakpoints['sm-plus'])) {
                      return (
                        <div
                          key={`drop-anchor${i}`}
                          onClick={() => {
                            commonStore.setFullPageSlideIndex(anchor.slideIndex);
                            this.toggleBurgerMenu();
                          }}
                          className="home-header__menu-item  home-header__menu-item_drop"
                        >
                          {translate(anchor.translateKey).replace(
                            /{{project_name}}/g,
                            appStore.app_info?.project_name || 'Vellness',
                          )}
                        </div>
                      );
                    }
                    return (
                      <ScrollLink
                        onClick={() => {
                          this.toggleBurgerMenu();
                        }}
                        to={anchor.sectionName}
                        key={`drop-anchor${i}`}
                        smooth
                        offset={-60}
                        className="home-header__menu-item  home-header__menu-item_drop"
                      >
                        {translate(anchor.translateKey).replace(
                          /{{project_name}}/g,
                          appStore.app_info?.project_name || 'Vellness',
                        )}
                      </ScrollLink>
                    );
                  })
                : null}
              {!isMainPage ? (
                <NavLink
                  to="/"
                  onClick={() => {
                    this.toggleBurgerMenu();
                  }}
                  className="home-header__menu-item  home-header__menu-item_drop  home-header__menu-item_route"
                >
                  {translate('HOME')}
                </NavLink>
              ) : null}
              {commonStore.menuRoutesArr?.length
                ? commonStore.menuRoutesArr.map((link, i) => {
                    return (
                      <NavLink
                        key={`route${i}`}
                        to={link.route}
                        onClick={() => {
                          this.toggleBurgerMenu();
                        }}
                        className="home-header__menu-item  home-header__menu-item_drop  home-header__menu-item_route"
                      >
                        {translate(link.translateKey)}
                      </NavLink>
                    );
                  })
                : null}
              <MediaQuery maxWidth={breakpoints.xs}>
                <a
                  onClick={commonStore.showAppMessage}
                  href={commonStore.getCurrentDeviceAppLink()}
                  className="home-header__menu-item home-header__menu-item_drop"
                >
                  {translate('APP_DOWNLOAD')}
                </a>
                <div className="home-header__socials">
                  <SocialItem socialName="facebook" />
                  <SocialItem socialName="instagram" />
                </div>
              </MediaQuery>
            </div>
            <div className="home-header__drop-bottom">
              <div className="home-header__drop-buttons">{this.renderSignButtons()}</div>
              <MediaQuery minWidth={breakpoints['sm-plus']}>
                <div className="home-header__socials">
                  <SocialItem app dark socialName="ios" />
                  <SocialItem app dark socialName="android" />
                  <SocialItem socialName="facebook" />
                  <SocialItem socialName="instagram" />
                </div>
              </MediaQuery>
            </div>
          </div>
        </div>
        {commonStore.showLoginPopUp && <LoginModal />}
      </>
    );
  }
}

NotAuthHeader.defaultProps = {
  showSignButtons: true,
  showBurger: true,
};

export default withRouter(NotAuthHeader);
