import React from 'react';
import bg from '../../../assets/images/checkoutGift/bg_blue.svg';
import bgM from '../../../assets/images/checkoutGift/bg_blue_m.svg';
import giftTop from '../../../assets/images/checkoutGift/gift_top.png';
import giftCenter from '../../../assets/images/checkoutGift/gift.png';
import giftLeft from '../../../assets/images/checkoutGift/gift_left.png';
import star from '../../../assets/images/checkoutGift/star.png';
import smurf1 from '../../../assets/images/checkoutGift/smurf1.png';
import smurf2 from '../../../assets/images/checkoutGift/smurf2.png';
import smurf3 from '../../../assets/images/checkoutGift/smurf3.png';
import smurf4 from '../../../assets/images/checkoutGift/smurf4.png';
import { translate } from '@/utils';
import { useStores } from '@/store';

const GiftBlue = () => {
  const { app_info, ln } = useStores().appStore;
  const images = {
    default: 'en_smurfBig.png',
    ru: 'ru_smurfBig.png',
  };
  const currentImage = images[ln] || images.default;

  return (
    <div className="gift-blue">
      <img className="gift-blue__bg" src={bg} alt="" />
      <img className="gift-blue__bg-m" src={bgM} alt="" />
      <img className="gift-blue__gift-top" src={giftTop} alt="" />
      <img className="gift-blue__gift-center" src={giftCenter} alt="" />

      <div className="gift-blue__inner">
        <div className="gift-blue__title">{translate('GIFT_TITLE')}</div>
        <div className="gift-blue__note">
          <div className="gift-blue__note-text">
            {translate('GIFT_NOTE').replace(
              /{{project_name}}/g,
              app_info?.project_name || 'VELLNESS',
            )}
          </div>
          <div className="gift-blue__note-rating">
            <div className="gift-blue__rating-line">
              <span className="gift-blue__rating-line-text">{translate('GIFT_RATING')}</span>{' '}
              <span className="gift-blue__rating-line-value">{translate('GIFT_RATING_VALUE')}</span>{' '}
              <img src={star} alt="" />
            </div>
            <img src={`/images/gift/${currentImage}`} alt="" className="gift-blue__smurf-big" />
          </div>
        </div>
        <div className="gift-blue__list">
          <img className="gift-blue__gift-m" src={giftCenter} alt="" />
          <div className="gift-blue__list-item">
            <img className="gift-blue__gift-left" src={giftLeft} alt="" />
            <div className="gift-blue__list-image">
              <img className="gift-blue__smurf1" src={smurf1} alt="" />
            </div>
            <div className="gift-blue__list-inner">
              <div className="gift-blue__list-title">{translate('GIFT_BENEFITS_TITLE1')}</div>
              <div className="gift-blue__list-text">{translate('GIFT_BENEFITS_TEXT1')}</div>
            </div>
          </div>
          <div className="gift-blue__list-item">
            <div className="gift-blue__list-image">
              <img className="gift-blue__smurf2" src={smurf2} alt="" />
            </div>
            <div className="gift-blue__list-inner">
              <div className="gift-blue__list-title">{translate('GIFT_BENEFITS_TITLE2')}</div>
              <div className="gift-blue__list-text">{translate('GIFT_BENEFITS_TEXT2')}</div>
            </div>
          </div>
          <div className="gift-blue__list-item">
            <div className="gift-blue__list-image">
              <img className="gift-blue__smurf3" src={smurf3} alt="" />
            </div>
            <div className="gift-blue__list-inner">
              <div className="gift-blue__list-title">{translate('GIFT_BENEFITS_TITLE3')}</div>
              <div className="gift-blue__list-text">{translate('GIFT_BENEFITS_TEXT3')}</div>
            </div>
          </div>
          <div className="gift-blue__list-item">
            <div className="gift-blue__list-image">
              <img className="gift-blue__smurf4" src={smurf4} alt="" />
            </div>
            <div className="gift-blue__list-inner">
              <div className="gift-blue__list-title">{translate('GIFT_BENEFITS_TITLE4')}</div>
              <div className="gift-blue__list-text">{translate('GIFT_BENEFITS_TEXT4')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftBlue;
