import React, { useState } from 'react';
import { translate } from '@/utils';
import moment from 'moment';
import WeightChart from '@components/Checkout/WeightChart';
import { useStores } from '@/store';
import { observer } from 'mobx-react';
import blogger from '@images/blogger.webp';
import resize from '@icons/resize.svg';

const PlanSection = ({ isCheckoutPage, hideButton }) => {
  const { state, changeQuizData } = useStores().quizStore;
  const { app_info } = useStores().appStore;
  const { weight, idealWeight } = state.survey.data || {};

  const minCourseDuration = weight - idealWeight < 6 ? 2 : 3;
  const metricSystemFormulaParams = {
    imp: {
      maxGoal: 72,
      maxProgress: 14,
      minDuration: minCourseDuration,
      weightUnit: 'lb',
    },
    metr: {
      maxGoal: 36,
      maxProgress: 7,
      minDuration: minCourseDuration,
      weightUnit: 'kg',
    },
  };
  const metricSystemType = localStorage.getItem('ms') || 'imp';
  const formulaData = metricSystemFormulaParams[metricSystemType] || metricSystemFormulaParams.imp;

  const goal =
    weight - idealWeight < formulaData.maxGoal ? weight - idealWeight : formulaData.maxGoal;
  const maxProgress = formulaData.maxProgress;
  const courseDuration =
    Math.abs(goal / maxProgress) > formulaData.minDuration
      ? Math.abs(Number((goal / maxProgress).toFixed(0)))
      : formulaData.minDuration;
  const progressDate = moment().add(courseDuration, 'months');

  const nextButtonHandleClick = () => {
    changeQuizData('pageName', 'select-plan');
  };
  const showAbSection = Boolean(JSON.parse(localStorage.getItem('showBlogger')));
  const [abActive, setAbActive] = useState(false);

  return (
    <div className={`plan-section ${isCheckoutPage ? 'plan-section_full' : ''}`}>
      {showAbSection ? (
        <div className="plan-section__column_ab plan-section__column">
          <div
            className={`plan-section__blogger ${abActive ? 'plan-section__blogger_active' : ''}`}
          >
            <img src={blogger} alt="" className="plan-section__blogger-img" />
            <div className="plan-section__blogger-desktop">
              <p>
                <b>Привет, я - Альбина, новатор в методах здорового образа жизни</b>
              </p>
              <p>
                Хочешь привести себя в форму и получить фигуру мечты? Питаться вкусно, но при этом
                худеть и не вредить здоровью? Получить результат и не растерять его за месяц? Тогда
                тебе на мой курс стройности, лёгкости и красоты!
              </p>
            </div>
            <div className="plan-section__blogger-mobile">
              <p>
                Ваш персональный план подготовлен дипломированным нутрицологом высшей категории{' '}
                <b>Альбина Голубева.</b>
              </p>
              <div onClick={() => setAbActive(true)} className="plan-section__blogger-resize">
                <img src={resize} alt="" />
              </div>
            </div>
          </div>

          <div className="plan-section__sub-title">Особенности вашего персонального плана</div>

          <div className="plan-section__list plan-section__list_ab">
            <div className="plan-section__list-item">
              <span className="plan-section__list-icon" />
              <span>Психология похудения.</span>
              Понимание себя и&nbsp;своих привычек.
            </div>
            <div className="plan-section__list-item">
              <span className="plan-section__list-icon" />
              <span>Еда: как есть, что есть, когда есть?</span>
              Персональный рацион, коррекция&nbsp;пищевых привычек
            </div>
            <div className="plan-section__list-item">
              <span className="plan-section__list-icon plan-section__list-icon_step2" />
              <span>Физическая активность</span>
              Персональные программы тренировок и&nbsp;формирование новых привычек
            </div>
            <div className="plan-section__list-item">
              <span className="plan-section__list-icon plan-section__list-icon_step2" />
              <span>Здоровый сон и отдых</span>
              Восстановление и&nbsp;расслабление
            </div>
            <div className="plan-section__list-item">
              <span className="plan-section__list-icon plan-section__list-icon_step2" />
              <span>Биомаркеры</span>
              Ищем индивидуальную генетическую непереносимость продуктов
            </div>
            <div className="plan-section__list-item">
              <span className="plan-section__list-icon plan-section__list-icon_step3" />
              <span>Дыхание</span>
              Помогаем работе нервной системы.
            </div>
            <div className="plan-section__list-item">
              <span className="plan-section__list-icon plan-section__list-icon_step3" />
              <span>Нутрициология</span>
              Восполняем дефициты микроэлементов и&nbsp;убираем лишнее и вредное.
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`plan-section__column ${isCheckoutPage ? 'plan-section__column_center' : ''}`}
        >
          <div className="plan-section__sub-title">
            {translate('CUSTOMIZED_PLAN').replace(
              /{{project_name}}/g,
              app_info?.project_name || 'Vellness',
            )}
          </div>

          <div className="plan-section__list">
            <div className="plan-section__list-item">
              <span className="plan-section__list-icon" />
              <span>{translate('FOCUSING_ON_BUILDING')}</span>
            </div>
            <div className="plan-section__list-item">
              <span className="plan-section__list-icon plan-section__list-icon_step2" />
              <span>{translate('CUSTOMIZATION_FOR_PHYSICAL')}</span>
            </div>
            <div className="plan-section__list-item">
              <span className="plan-section__list-icon plan-section__list-icon_step3" />
              <span>{translate('TAILORED_OUR_LIFESTYLE')}</span>
            </div>
          </div>
        </div>
      )}
      {!isCheckoutPage && (
        <div className="plan-section__column plan-section__column_right">
          <div
            className="plan-section__title plan-section__title_goal"
            dangerouslySetInnerHTML={{
              __html: translate('BASED_ON_YOUR_ANSWERS')
                .replace('{{idealWeight}}', weight - goal)
                .replace('{{unitWeight}}', translate(formulaData.weightUnit))
                .replace('{{progressDate}}', moment(progressDate).format('DD.MM.YY')),
            }}
          />
          <WeightChart
            weightUnit={translate(formulaData.weightUnit)}
            goal={goal}
            weight={weight}
            idealWeight={idealWeight}
            courseDuration={courseDuration}
          />
        </div>
      )}

      {!hideButton && (
        <div className="plan-section__btn">
          <button className="orange-button button-with-arrow " onClick={nextButtonHandleClick}>
            {translate('NEXT')}
          </button>
        </div>
      )}
    </div>
  );
};

export default observer(PlanSection);
